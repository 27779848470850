import { Box, styled, Typography } from "@mui/material";
import { CardMuted } from "components/Common/styled";

export const MainContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: 20,
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    gap: 18,
  },
}));

export const Bold = styled(Typography)(() => ({
  fontWeight: "bold",
}));

export const TokenCard = styled(CardMuted)(({ borderColor }) => ({
  cursor: "pointer",
  height: 155,
  display: "flex",
  gap: 20,
  flexDirection: "column",
  boxSizing: "border-box",
  justifyContent: "space-between",
  background: "#090909",
  padding: 20,
  border: `1px solid ${borderColor}`,
  // boxShadow: `0px 1px 3px rgba(0, 0, 0, 0.2), 0px 0px 5px ${borderColor}`,
  transition: "box-shadow 0.3s ease-in-out, transform 0.3s ease", // Ensure transform is included in the transition

  "&:hover": {
    boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.2), 0px 0px 10px ${borderColor}`,
    transform: "translateY(-2px)", // Move the card up by 5px

    "& .MuiButtonBase-root": {
      opacity: 1,
    },
  },
}));

export const Card = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  height: 155,
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 15,
  background: "transparent",
  maxWidth: 390,
  "&:nth-child(n+4)": {
    marginTop: "50px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "0%",
    "&:nth-child(n+4)": {
      marginTop: "0px",
    },
  },
}));

export const CardSymbol = styled("img")(({ theme }) => ({
  width: 40,
  height: 40,
  [theme.breakpoints.down("sm")]: {
    width: 24,
    height: 24,
  },
}));

export const CardTitleWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    justifyContent: "start",
    width: "fit-content",
  },
}));

export const CardSymbolAndTitleWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    justifyContent: "center",
  },
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: 28,
  [theme.breakpoints.down("sm")]: {
    fontSize: 20,
  },
}));

export const SoonReleased = styled(Box)(() => ({
  background: "rgba(246, 107, 48, 0.1)",
  color: "#F66B30",
  padding: "5px 10px 5px 10px",
  borderRadius: 5,
}));

export const CardDescription = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  textAlign: "center",
  color: "#B3B3B3",
  [theme.breakpoints.down("sm")]: {
    textAlign: "left",
  },
}));
