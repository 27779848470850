import {
  Description,
  DescriptionContainer,
  SectionHeader,
} from "components/Common/styled";
import {
  CardContainer,
  CardsContainer,
  MainContainer,
  TokenCard,
  TokenCardRow,
  TokenCardTop,
  TokenImg,
  TokenNameContainer,
  TokenName,
  ShowMore,
} from "./styled";

import ethIcon from "assets/eth.svg";
import btcIcon from "assets/bitcoin.svg";
import bnbIcon from "assets/bnb.svg";
import arbIcon from "assets/arb.svg";
import dogeIcon from "assets/doge.svg";

import { useEffect, useState } from "react";

import { useMedia } from "react-use";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";

const IntroducingPalmswapV2 = () => {
  const isMobile = useMedia("(max-width: 1100px)");

  const formattedTokensInfo = [
    {
      name: "Bitcoin",
      symbol: "BTC",
      img: btcIcon,
      borderColor: "rgba(247, 147, 26, 0.2)",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      img: ethIcon,
      borderColor: "rgba(98, 126, 235, 0.3)",
    },
    {
      name: "BNB",
      symbol: "BNB",
      img: bnbIcon,
      borderColor: "rgba(240, 185, 11, 0.2)",
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      img: arbIcon,
      borderColor: "rgba(130, 71, 229, 0.3)",
    },
    {
      name: "Dogecoin",
      symbol: "DOGE",
      img: dogeIcon,

      borderColor: "rgba(130, 71, 229, 0.3)",
    },
  ];

  const [showAllTokens, setShowAllTokens] = useState(!isMobile);

  const displayedTokens = showAllTokens
    ? formattedTokensInfo
    : formattedTokensInfo.slice(0, 3);

  const toggleShowAllTokens = () => {
    setShowAllTokens(!showAllTokens);
  };

  useEffect(() => {
    if (isMobile) {
      setShowAllTokens(false);
    } else {
      setShowAllTokens(true);
    }
  }, [isMobile]);

  const buttonText = showAllTokens ? "Show Less" : "Show More";

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <MainContainer>
      <DescriptionContainer>
        <SectionHeader>Start Trading</SectionHeader>

        <Description>
          We are continuously launching new Perpetual Contract markets.
        </Description>
      </DescriptionContainer>
      <CardsContainer>
        <CardContainer>
          {displayedTokens.map((token) => (
            <a
              href="https://perp.foxify.trade/"
              target="_blank"
              rel="noopener noreferrer"
              key={token.name}
              style={{
                textDecoration: "none",
                color: "inherit",
                width: isSmallScreen ? "100%" : "15%",
                boxSizing: "border-box",
              }}
            >
              <TokenCard borderColor={token.borderColor}>
                <TokenCardRow>
                  <TokenCardTop>
                    <TokenImg src={token.img} />
                    <TokenNameContainer>
                      <TokenName>{token.name}</TokenName>
                      <Description>{token.symbol}</Description>
                    </TokenNameContainer>
                  </TokenCardTop>
                </TokenCardRow>
              </TokenCard>
            </a>
          ))}
          {isMobile && (
            <ShowMore onClick={toggleShowAllTokens}>{buttonText}</ShowMore>
          )}
        </CardContainer>
      </CardsContainer>
    </MainContainer>
  );
};

export default IntroducingPalmswapV2;
