import { Box, styled } from "@mui/material";

export const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 15,
});

export const Title = styled(Box)(({ theme }) => ({
  fontSize: 55,
  color: "white",
  fontWeight: 700,
  textAlign: "center",
  textTransform: "uppercase",

  [theme.breakpoints.down("sm")]: {
    fontSize: 28,
  },
}));

export const Description = styled(Box)(({ theme }) => ({
  color: "white",
  maxWidth: 600,
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
    padding: "0 16px",
  },
}));

export const Buttons = styled(Box)({
  display: "flex",
  gap: 10,
});

export const Container = styled(Box)({
  marginTop: 120,
  marginBottom: 120,
});
