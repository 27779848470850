import xLogo from "assets/x.svg";

type SubNav = {
  title: string;
  route: string;
  description?: string;
  target?: string;
  icon?: string;
};

export type NavItem = {
  title: string;
  route: string;
  children?: SubNav[];
  target?: string;
};

export const NAVS: NavItem[] = [
  {
    title: "Explore",
    route: "",
  },
  {
    title: "Docs",
    route: "https://docs.foxify.trade/",
    target: "_blank",
  },
  {
    title: "Community",
    route: "",
    children: [
      {
        title: "Twitter",
        route: "https://twitter.com/foxifytrade",

        icon: xLogo,
        target: "_blank",
      },
      {
        title: "Discord",
        route: "https://discord.com/invite/foxifytrade",

        icon: "assets/discord.svg",
        target: "_blank",
      },

      {
        title: "Telegram",
        route: "https://t.me/foxifytradecommunity",

        icon: "assets/telegram.svg",
        target: "_blank",
      },
    ],
  },
  // {
  //   title: "Company",
  //   route: "",
  //   children: [
  //     {
  //       title: "Careers",
  //       route: "https://web3.career/web3-companies/palmswap",
  //       description: "Find your dream job.",
  //       icon: "assets/careers.svg",
  //       target: "_blank",
  //     },
  //     {
  //       title: "Media Kit",
  //       route: "https://docs.palmswap.org/brand-and-logo",
  //       description: "All media you need",
  //       icon: "assets/mediakit.svg",
  //       target: "_blank",
  //     },
  //   ],
  // },
  // {
  //   title: "Blog",
  //   route: "https://blog.palmswap.org",
  //   target: "_blank",
  // },
  // {
  //   title: "Academy",
  //   route: "https://academy.palmswap.org",
  //   target: "_blank",
  // },
  // {
  //   title: "Liquidity",
  //   route: "/liquidity",
  // },
];
if (process.env.REACT_APP_SHOW_NFT) {
  NAVS.push({
    title: "Alpha NFT",
    route: "/alpha_nft",
  });
}
