import {
  Description,
  DescriptionContainer,
  SectionHeader,
} from "components/Common/styled";
import {
  Card,
  CardContainer,
  CardDesctiptionContainer,
  MainContainer,
  WhiteText,
  CardImg,
} from "./styled";

import vaultIcon from "assets/VaultIcon.svg";
import chartIcon from "assets/chartIcon.svg";
import borrowFeeIcon from "assets/BorrowFeeIcon.svg";

const Earn = () => {
  return (
    <MainContainer>
      <DescriptionContainer>
        <SectionHeader>
          LPs earn a share of every transaction on foxify
        </SectionHeader>
        <Description>
          Palmswap liquidity providers earn a 40% share or higher of every
          transaction generated on foxify V2. These transactions can be
          categorized into 3 types: transaction fees, borrowing fees, and
          liquidation fees.
        </Description>
      </DescriptionContainer>
      <CardContainer>
        <Card>
          <CardImg src={vaultIcon} />
          <WhiteText>Trading fee</WhiteText>
          <CardDesctiptionContainer>
            <Description>
              To open a position, traders pay a maximum 0.1% fee of the position
              size opened. The same amount must be paid to close a position. The
              Position Fee is paid in USDT.
            </Description>
          </CardDesctiptionContainer>
        </Card>
        <Card>
          <CardImg src={chartIcon} />
          <WhiteText>Liquidation Penalty</WhiteText>
          <CardDesctiptionContainer>
            <Description>
              In the event of liquidation, 100% of the penalty goes to the PLP
              vault, thus distributing 100% of this amount to the liquidity
              providers.
            </Description>
          </CardDesctiptionContainer>
        </Card>
        <Card>
          <CardImg src={borrowFeeIcon} />
          <WhiteText>Borrower fee</WhiteText>
          <CardDesctiptionContainer>
            <Description>
              A borrow fee is the fee paid to the counter-party of users’ trades
              to incentivize them to keep the total position size from diverging
              significantly with total collateral
            </Description>
          </CardDesctiptionContainer>
        </Card>
      </CardContainer>
    </MainContainer>
  );
};

export default Earn;
