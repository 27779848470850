import { Box, Link, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "config/colors";
import CallMadeTwoToneIcon from "@mui/icons-material/CallMadeTwoTone";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

export const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  marginTop: 130,
  padding: "0 150px",
  marginBottom: 230,
  gap: 60,
  [theme.breakpoints.down("sm")]: {
    padding: "0 16px",
    marginBottom: 80,
    flexDirection: "column",
    marginTop: 90,
    gap: 60,
  },
  [theme.breakpoints.up(2120)]: {
    justifyContent: "center",
  },
}));

export const LeftTop = styled(Box)(({ theme }) => ({
  width: 1100,
  minWidth: 500,
  gap: 30,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    minWidth: "100%",
    textAlign: "center",
  },
}));

export const Background = styled(Box)(() => ({
  width: "100%",
  background:
    "linear-gradient(to bottom, rgba(203, 187, 250, 0.03), rgba(245, 108, 49, 0.1))",
  position: "absolute",
  top: "60px",
  left: 0,
  overflow: "hidden",
  height: "calc(100% - 60px)",
}));
export const Background2 = styled(Box)(() => ({
  width: "100%",
  background: colors.background1,
  position: "absolute",
  top: "60px",
  left: 0,
  overflow: "hidden",
  height: "calc(100% - 60px)",
}));

export const Banner = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "rgba(255,255,255,0.05)",
  position: "absolute",
  top: "60px",
  left: 0,
  height: 41,
  color: colors.main,
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100,
  fontSize: 14,
  backdropFilter: "blur(10px)",
  display: "flex",
  gap: 10,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const LinkWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
}));

export const ArrowImage = styled("img")({
  width: "150%",
  height: "150%",
});

export const BannerLink = styled(Link)({
  textDecoration: "underline",
  color: colors.white,
  fontSize: 14,
  cursor: "pointer",
});

export const BackgroundImage = styled("img")(({ theme }) => ({
  width: "150%",
  height: "150%",
  position: "absolute",
  zIndex: 100,
  opacity: 0.1,
  top: "-65%",
  left: "-25%",
  [theme.breakpoints.down("sm")]: {
    width: "500%",
    height: "150%",
    top: "-25%",
    left: "-158%",
    opacity: 0.1,
  },
}));
export const RightBottom = styled(Box)(({ theme }) => ({
  height: 690,
  position: "relative",
  zIndex: 100,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: 35,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 55,
  fontWeight: "bold",
  textTransform: "uppercase",
  color: colors.white,
  zIndex: 10,
  [theme.breakpoints.down("sm")]: {
    fontSize: 28,
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: colors.white,
  fontWeight: 300,
  zIndex: 10,
  maxWidth: 750,
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: 400,
    fontSize: 14,
  },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  zIndex: 100,

  gap: 10,
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  overflow: "hidden",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const Card1 = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 160,
  background: colors.gradientMain,
  overflow: "hidden",
  borderRadius: 25,
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: 22,
  padding: 16,
}));

export const Card2 = styled(Box)(({ theme }) => ({
  width: 355,
  height: 110,
  background: colors.border,
  borderRadius: 25,
  display: "flex",
  alignItems: "center",
  gap: 22,
  padding: 16,
}));

export const CardImg = styled("img")({
  width: 237,
  marginBottom: "-80px",
});

export const CardArrowIcon = styled(CallMadeTwoToneIcon)({
  fontSize: 20,
  color: colors.white,
});

export const CardFlexContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  textAlign: "start",
  textDecoration: "none",
});

export const CardWhiteText = styled(Typography)({
  fontSize: 24,
  fontWeight: "bold",
  color: colors.white,
  textDecoration: "none",
});

export const ClockIconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 4,
});

export const CardInactiveText = styled(Typography)({
  fontSize: 16,
  color: colors.inactive,
});

export const ClockIcon = styled(WatchLaterIcon)({
  fontSize: 16,
  color: colors.inactive,
});

export const CardWhiteTextSmall = styled(Typography)({
  fontSize: 12,
  color: colors.white,
  textDecoration: "none",
});

export const CardSwitchContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 4,
});

export const CardSwitch = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }: { active?: boolean }) => ({
  cursor: "pointer",
  width: 10,
  height: 10,
  borderRadius: 5,
  background: active ? colors.white : colors.border,
}));

export const WaveContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  // top: 0,
  // left: 0,
  // zIndex: 0,
  background: "red",
}));

export const Wave1 = styled("img")(() => ({
  width: 332,
  height: 500,
  position: "absolute",
  left: -160,
  bottom: -100,
  zIndex: -1,
}));

export const Wave2 = styled("img")(() => ({
  width: 332,
  height: 500,
  position: "absolute",
  right: -160,
  bottom: -100,
  zIndex: -1,
}));

export const ExchangeImg = styled("img")(({ theme }) => ({
  width: 1200,
  height: "100%",
  position: "relative",
  left: 0,
  top: 0,
  background: colors.black,
  borderRadius: 25,
  zIndex: 2,
}));

export const ExchangeImgMobile = styled("img")(({ theme }) => ({
  width: 275,
  height: "100%",
  position: "relative",
  left: 0,
  top: 0,
  background: colors.black,
  borderRadius: 25,
  zIndex: 2,
}));

// TODO: delete any
const HighlightStyle: any = {
  WebkitFilter: "blur(70px)",
  filter: "blur(70px)",
  zIndex: 1,
  position: "absolute",
  overflow: "hidden",
  transition: "filter .2s ease-in-out",
  willChange: "transform",
};

export const Highlight2 = styled(Box)(({ theme }) => ({
  ...HighlightStyle,
  background:
    "radial-gradient(50% 50% at 50% 50%, #3D1504 0%, rgba(0, 0, 0, 0) 100%)",
  height: 936,
  width: 1214,
  WebkitFilter: "blur(100px)",
  filter: "blur(100px)",

  [theme.breakpoints.down("sm")]: {
    width: 454,
    height: 242,
    left: -60,
    top: -50,
    WebkitFilter: "blur(20px)",
    filter: "blur(20px)",
  },
}));

export const Highlights = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: -500,
  zIndex: 0,
  left: "15%",
  [theme.breakpoints.down("sm")]: {
    left: 30,
    top: -30,
  },
}));

export const ExchangeImageContainer = styled(Box)(({ theme }) => ({
  padding: 5,
  background:
    "linear-gradient(to bottom, rgba(255,255,255,0.1)  0%, rgba(8,8,18,0) 100%)",

  borderRadius: 25,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
}));

export const MaxTradeSize = styled("span")(() => ({
  color: colors.main,
}));

export const Section = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: "0 150px",
  paddingBottom: 75,
  gap: 60,
  position: "relative",
  [theme.breakpoints.down("md")]: {
    padding: "0 40px",
    paddingBottom: 75,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 16px",
    paddingBottom: 75,
    flexDirection: "column",
    gap: 60,
  },
  [theme.breakpoints.up(2120)]: {
    justifyContent: "center",
  },
}));

export const FundedLogo = styled("img")({
  height: 70,
  marginTop: 150,
  zIndex: 10,
  objectFit: "contain",
});

export const StyledBr = styled("br")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
