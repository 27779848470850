import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  styled,
  TextField,
  Typography,
  Button,
  keyframes,
} from "@mui/material";
import { colors } from "config/colors";
import CallMadeTwoToneIcon from "@mui/icons-material/CallMadeTwoTone";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import NewsletterBackground from "assets/Newsletter.png";
import { SvgIconProps } from "@mui/material/SvgIcon";

const bounceIn = keyframes`
  0% {
    transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
  100% {
    transform: translate3d(100%, -100%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
`;

const bounceOut = keyframes`
  0% {
    transform: translate3d(100%, -100%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
  100% {
    transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
`;

export const LinkButtonStyled = styled(Button)({
  width: "fit-content",
  whiteSpace: "nowrap",
  "& .MuiButton-endIcon": {
    overflow: "hidden",
  },
  "&:hover": {
    "& .arrows-box": {
      animation: `${bounceIn} 0.2s ease`,
    },
  },
});

export const ArrowsBox = styled(Box)({
  position: "relative",
  height: 16,
  width: 16,
  display: "flex",
  alignItems: "center",
  animation: `${bounceOut} 0.2s ease`,
});

// Extend the SvgIconProps to include isWhite
interface LinkButtonIconProps extends SvgIconProps {
  isWhiteArrow?: boolean;
}

export const LinkButtonIcon = styled(CallMadeTwoToneIcon)<LinkButtonIconProps>(
  ({ isWhiteArrow }) => ({
    fontSize: 14,
    color: isWhiteArrow ? colors.white : colors.black, // Use isWhite to determine color
    width: 16,
    height: 16,
  })
);

export const LinkButtonIconSecond = styled(
  CallMadeTwoToneIcon
)<LinkButtonIconProps>(({ isWhiteArrow }) => ({
  fontSize: 14,
  color: isWhiteArrow ? colors.white : colors.black,
  width: 16,
  height: 16,
  position: "absolute",
  top: "100%",
  bottom: "auto",
  left: "-100%",
  right: "auto",
}));

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  width: 520,
  display: "flex",
  flexDirection: "column",
  gap: 10,
  textAlign: "center",
  alignItems: "center",
  marginBottom: 70,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginBottom: 30,
  },
}));

export const SectionHeader = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  color: colors.white,
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: 28,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
  },
}));

export const WhiteText = styled(Typography)({
  fontSize: 20,
  color: colors.white,
  fontWeight: "bold",
});

export const BigWhiteText = styled(Typography)({
  fontSize: 30,
  color: colors.white,
  fontWeight: "bold",
  fontFamily: '"MazzardH-Black", serif',
  letterSpacing: 0,
});

export const PurpleText = styled(Typography)({
  fontSize: 14,
  color: colors.main,
  marginTop: "auto",
  marginBottom: 15,
});

export const Description = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 300,
  color: colors.inactive, // Assuming theme.colors.inactive is defined in your theme
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

export const CardMuted = styled(Box)({
  borderRadius: 25,
  background: colors.gradientMuted,
  border: `1px solid ${colors.border}`,
});

export const CardPurpleGradient = styled(Box)({
  background: "linear-gradient(0deg, #14121a 0%, #3b1e78 100%)",
  borderRadius: 25,
  border: "1px solid rgba(255, 255, 255, 0.06)",
});

export const PearlGradientBox = styled(Box)({
  background:
    "linear-gradient(130deg, rgba(2, 187, 133, 1) 7%, rgba(17, 157, 179, 1) 17%, rgba(30, 134, 191, 1) 30%, rgba(58, 89, 187, 1) 43%, rgba(73, 58, 190, 1) 57%, rgba(104, 7, 184, 1) 69%, rgba(62, 15, 149, 1) 98%, rgba(6, 4, 90, 1) 100%)",
});

export const EmailBoxContainer = styled(Box)(({ theme }) => ({
  borderRadius: 45,
  width: "100%",
  padding: 60,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundImage: `url(${NewsletterBackground})`, // Use the imported image
  backgroundSize: "cover", // Adjust as needed
  backgroundRepeat: "no-repeat", // Adjust as needed
  backgroundPosition: "center", // Adjust as needed
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    flexDirection: "column",
    padding: 20,
    paddingTop: 40,
    paddingBottom: 40,
    gap: 50,
    height: 449,
    textAlign: "center",
    borderRadius: 25,
  },
}));

export const EmailInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 70,
  width: 360,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    gap: 20,
  },
}));

export const EmailDescription = styled(Typography)(() => ({
  fontSize: 16,
  color: colors.white,
}));

export const InputContainer = styled(Box)(() => ({
  position: "relative",
}));

export const InputButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 1,
  top: 1,
  width: 48,
  height: 48,
  borderRadius: 24,
  background: colors.border,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const InputButtonIcon = styled(ArrowForwardOutlinedIcon)(() => ({
  fontSize: 16,
  color: colors.white,
}));

export const EmailInput = styled(TextField)(() => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    height: 50,
    borderRadius: 25,
    fontSize: 14,
    "& fieldset": {
      borderColor: colors.border,
    },
    "&:hover fieldset": {
      borderColor: colors.border,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.border,
      borderWidth: 1,
    },
  },
}));

export const StatusSuccess = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  height: 50,
  gap: 12,
}));

export const StyledErrorText = styled(FormHelperText)(() => ({
  fontSize: "12px",
  color: `${colors.red} !important`,
  height: 0,
  marginTop: -1,
  transform: "translateY(5px)",
}));

export const StyledFormControl = styled(FormControl)(() => ({
  position: "relative",
  width: "100%",
}));

export const SuccessText = styled(Box)(() => ({
  fontSize: 16,
  color: colors.white,
}));

export const SuccessButton = styled(IconButton)(() => ({
  width: 48,
  height: 48,
  borderRadius: 24,
  background: "#00C07538",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const SuccessButtonIcon = styled(CheckRoundedIcon)(() => ({
  fontSize: 16,
  color: "#00C075",
}));

export const SoonReleased = styled(Box)(() => ({
  background: "rgba(246, 107, 48, 0.1)",
  color: "#F66B30",
  padding: "5px 10px 5px 10px",
  fontSize: 14,
  fontWeight: "normal",
  borderRadius: 5,
}));
