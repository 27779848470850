import { Box, Link, styled, Typography } from "@mui/material";
import { colors } from "config/colors";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import CallMadeIcon from "@mui/icons-material/CallMade";

export const MoreIcon = styled(ExpandMoreRoundedIcon)({
  fontSize: 18,
  color: colors.white,
  cursor: "pointer",
});

export const LessIcon = styled(ExpandLessRoundedIcon)({
  fontSize: 18,
  color: colors.white,
  cursor: "pointer",
});

export const LinkIcon = styled(CallMadeIcon)({
  fontSize: 18,
  color: colors.white,
  cursor: "pointer",
});

export const LinkGroup = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "inner",
})(({ active, inner }: { active: boolean; inner?: boolean }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  width: "100%",
  padding: inner ? "8px 0px" : "8px 16px",
  background: active ? "#0F1014" : "transparent",
}));

export const LinkGroupNameContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const LinkGroupLinkContainer = styled(Link)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  textDecoration: "none",
});

export const LinkGroupName = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  color: colors.white,
});

export const LinkGroupBody = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

export const LinkGroupBodyItem = styled("a")({
  fontSize: 12,
  color: colors.inactive,
  lineHeight: 1.5,
  textDecoration: "none",
  cursor: "pointer",
});

export const ExploreMenuItemsContainer = styled(Box)({
  display: "flex !important",
  flexDirection: "column",
  gap: 6,
  borderRadius: 20,
});

export const ExploreMenuItem = styled(Link, {
  shouldForwardProp: (prop) => prop !== "disabled",
})(({ disabled }: { disabled: boolean }) => ({
  display: "flex !important",
  borderRadius: 10,
  padding: 10,
  gap: 6,
  cursor: !disabled && "pointer",
  textDecoration: "none",
  "&:hover": {
    background: !disabled && colors.border,
  },
}));

export const ExploreMenuItemTextContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

export const ExploreMenuItemIcon = styled("img")({
  width: 22,
  height: 22,
});

export const ExploreMenuItemHeaderContainer = styled(Typography)({
  display: "flex !important",
  gap: 6,
  flexWrap: "wrap",
});

export const ExploreMenuItemTag = styled(Typography)({
  height: 20,
  border: "1px solid #F66B31",
  padding: "0 10px",
  textAlign: "center",
  color: colors.main,
  fontSize: 11,
  borderRadius: 7,
});

export const ExploreMenuItemHeader = styled(Typography)({
  fontSize: 14,
  color: colors.white,
  fontWeight: "bold",
});

export const ExploreMenuItemDescription = styled(Typography)({
  fontSize: 11,
  color: colors.inactive,
});

export const StyledMenuText = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

export const StyledMenuItem = styled(Link)({
  display: "flex !important",
  borderRadius: 10,
  padding: 10,
  gap: 6,
  cursor: "pointer",
  textDecoration: "none",
  "&:hover": {
    background: colors.border,
  },
});

export const StyledTitle = styled(Typography)({
  marginLeft: 10,
  marginBottom: 10,
  color: colors.inactive,
  fontSize: 10,
  textTransform: "uppercase",
});

export const StyledMenuHeader = styled(Box)({
  fontSize: 14,
  color: colors.white,
  fontWeight: "bold",
});

export const StyledDescription = styled(Typography)({
  fontSize: 11,
  color: colors.inactive,
});
