import {
  Description,
  DescriptionContainer,
  SectionHeader,
} from "components/Common/styled";
import {
  CardContainer,
  CardsContainer,
  MainContainer,
  Card,
  CardSymbol,
  CardTitleWrapper,
  CardTitle,
  SoonReleased,
  CardDescription,
  CardSymbolAndTitleWrapper,
} from "./styled";

import checkMark from "assets/checkMark.svg";
import phoneIcon from "assets/phone.svg";
import chartIcon from "assets/chartSymbol.svg";
import oneClickIcon from "assets/oneClick.svg";
import fiatIcon from "assets/fiatIcon.svg";

type LeadingObjectsInfo = {
  title: string;
  img: string;
  description: string;
  isReleased: boolean;
};

const LeadingTheFrontier = () => {
  const LeadingObjects: LeadingObjectsInfo[] = [
    {
      title: "Guaranteed Execution",
      img: checkMark,
      description:
        "Never miss an opportunity. Our platform ensures your trades are executed without fail.",
      isReleased: true,
    },
    {
      title: "Trade on the Go",
      img: phoneIcon,
      description:
        "Trade from anywhere, at any time. Experience seamless trading on your mobile device.",
      isReleased: true,
    },
    {
      title: "On Chart Trading",
      img: chartIcon,
      description:
        "Execute quick orders directly on the chart for efficient trading.",
      isReleased: true,
    },
    {
      title: "One-Click Trading",
      img: oneClickIcon,
      description:
        "Skip the wallet confirmations. Place your orders with a single click for hassle-free trading.",
      isReleased: false,
    },
    {
      title: "Fiat On/Off Ramps",
      img: fiatIcon,
      description:
        "Effortlessly transfer funds into and out of your account with our streamlined fiat on/off ramps.",
      isReleased: false,
    },
  ];

  return (
    <MainContainer>
      <DescriptionContainer>
        <SectionHeader>Leading the Frontier</SectionHeader>
        <Description>
          The best of DeFi meets a professional trading experience
        </Description>
      </DescriptionContainer>
      <CardsContainer>
        <CardContainer>
          {LeadingObjects.map((obj) => (
            <Card>
              <CardSymbolAndTitleWrapper>
                <CardSymbol src={obj.img} />
                <CardTitleWrapper>
                  <CardTitle>{obj.title}</CardTitle>
                  {obj.isReleased === false && (
                    <SoonReleased>Soon</SoonReleased>
                  )}
                </CardTitleWrapper>
              </CardSymbolAndTitleWrapper>
              <CardDescription>{obj.description}</CardDescription>
            </Card>
          ))}
        </CardContainer>
      </CardsContainer>
    </MainContainer>
  );
};

export default LeadingTheFrontier;
