import logo from "assets/logo.svg";
import { footerMenu2 as linkGroups } from "config/footerMenu";
import { useState } from "react";
import { useMediaQuery, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  FooterContainer,
  LessIcon,
  LinkGroup,
  LinkGroupBody,
  LinkGroupBodyItem,
  LinkGroupName,
  LinkGroupNameContainer,
  LinksContainer,
  Logo,
  MainLinkContainer,
  MoreIcon,
  LinkContainer,
  FooterBottom,
  Link,
  LinkAllRights,
  LinksWrapper,
  Highlights,
  Highlight,
  FooterWrapper,
  FooterBottomWrapper,
  Links,
} from "./styled";

const FooterMenu = ({ linkGroup }: { linkGroup: (typeof linkGroups)[0] }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <LinkGroup>
      <LinkGroupNameContainer onClick={() => setOpen(!open)}>
        <LinkGroupName>{linkGroup.name}</LinkGroupName>
        {!open && isSmallScreen && <MoreIcon />}
        {open && isSmallScreen && <LessIcon />}
      </LinkGroupNameContainer>
      {(open || !isSmallScreen) && (
        <LinkGroupBody>
          {linkGroup.links.map((link, i) =>
            link.link === "#" ? (
              <Tooltip
                key={link.name + i + "tooltip"}
                title="Coming soon"
                arrow
                style={{ fontSize: 12, color: "#6C7284", fontWeight: "500" }}
              >
                <Typography
                  key={link.name + i}
                  component={LinkGroupBodyItem}
                  href={link.link}
                  sx={{
                    fontSize: isSmallScreen ? "13px" : "16px",
                    fontWeight: "300",
                    color: "#787878",
                    ":hover": {
                      opacity: 1,
                      color: "#6f57d1",
                    },
                  }}
                >
                  {link.name}
                </Typography>
              </Tooltip>
            ) : (
              <LinkGroupBodyItem
                key={link.name + i}
                href={link.link}
                target="_blank"
              >
                {link.name}
              </LinkGroupBodyItem>
            )
          )}
        </LinkGroupBody>
      )}
    </LinkGroup>
  );
};

const Footer = function () {
  return (
    <FooterWrapper>
      <FooterContainer>
        <Highlights>
          <Highlight />
        </Highlights>

        <LinksContainer>
          <MainLinkContainer>
            <Logo src={logo} alt="palmLogo" />
          </MainLinkContainer>

          <LinkContainer>
            {linkGroups.map((linkGroup, i) => (
              <FooterMenu key={linkGroup.name + i} linkGroup={linkGroup} />
            ))}
          </LinkContainer>
        </LinksContainer>
      </FooterContainer>
      <FooterBottomWrapper>
        <FooterBottom>
          <LinksWrapper>
            <Links>
              <Link>Responsible Disclosure</Link>
            </Links>
            <Links>
              <LinkAllRights>© 2024 Foxify. All rights reserved.</LinkAllRights>
            </Links>
          </LinksWrapper>
        </FooterBottom>
      </FooterBottomWrapper>
    </FooterWrapper>
  );
};

export default Footer;
