import { Box, styled } from "@mui/material";
import { colors } from "config/colors";

export const Container = styled(Box)(({ theme }) => ({
  marginTop: 120,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0 60px",
  gap: 10,
  height: "auto",
  width: "100%",
  justifyContent: "center",

  [theme.breakpoints.down("lg")]: {
    padding: "0",
    flexWrap: "wrap",
    height: "auto",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    height: "auto",
  },
}));

export const Card = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  maxWidth: 300,
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 25,
  border: `1px solid ${colors.border}`,
  padding: "25px 15px",
  height: 245,
  minHeight: 225,

  [theme.breakpoints.down("sm")]: {
    maxWidth: "none",
    height: "auto",
  },
}));

export const Title = styled(Box)({
  color: colors.main,
  fontSize: 20,
  marginTop: 20,
});

export const Description = styled(Box)({
  marginTop: 5,
  fontSize: 14,
  color: colors.inactive,
  textAlign: "center",
});

export const ImageContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: 55,
});

export const Image = styled("img")({
  objectFit: "contain",
});

export const SectionTitle = styled(Box)({
  fontSize: 32,
  color: "white",
  fontWeight: 700,
  textAlign: "center",
  marginTop: 120,
});

export const QualifySeciton = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: 60,
  paddingLeft: 60,
  paddingRight: 60,
  [theme.breakpoints.down("lg")]: {
    padding: "0 40px",
    flexDirection: "column",
    gap: 30,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 16px",
    flexDirection: "column",
    gap: 30,
  },
}));

export const HowToQualifyImage = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const HowToQualifyImageContainer = styled(Box)({
  position: "relative",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const QualfiySteps = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 35,
  minWidth: 400,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    minWidth: "auto",
  },
}));

export const QualifyStep = styled(Box)(({ theme }) => ({
  display: "flex",
}));

export const QualifyStepTitle = styled(Box)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 500,
  color: "white",

  [theme.breakpoints.down("sm")]: {
    fontSize: 20,
  },
}));

export const QualifyStepDescription = styled(Box)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 300,
  color: colors.inactive,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

export const StepNumber = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: 24,
  color: colors.inactive2,
  marginRight: 15,

  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
}));

export const VerticalLine = styled(Box)({
  borderLeft: `1px solid ${colors.inactive2}`,
  height: 100,
});
