import LinkButton from "components/Common/LinkButton";
import { Buttons, Description, Section, Title, Container } from "./styled";

export default function FuturesTrading() {
  return (
    <Container>
      <Section>
        <Title>
          Futures trading,
          <br /> decentralized and simplified
        </Title>
        <Description>
          Trade perpetual contracts from only 10 USDC with up to 50x leverage,
          and low fees directly from your wallet.
        </Description>
        <Buttons>
          <LinkButton href="https://perp.foxify.trade">
            Start Trading
          </LinkButton>
          <LinkButton
            href="https://docs.palmswap.org/"
            color="secondary"
            target="_blank"
          >
            Docs
          </LinkButton>
        </Buttons>
      </Section>
    </Container>
  );
}
