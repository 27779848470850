import { Box, styled, Typography } from "@mui/material";

import { colors } from "config/colors";

export const MainContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));

export const SocialsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 470,
  position: "relative",
  display: "flex",
  flexDirection: "row",
  overflow: "hidden",
  borderRadius: 50,
  zIndex: 100,
  background: colors.background1,

  border: `1px solid ${colors.border}`,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
    height: "fit-content",
    width: "100%",
    paddingBottom: 50,
    overflow: "hidden",
  },
}));

export const SocialsLeft = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 20,
  padding: "100px 50px",
  [theme.breakpoints.down("sm")]: {
    padding: "50px 20px",
  },
}));

export const SocialsRight = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: 0,
  [theme.breakpoints.down("sm")]: {
    gap: 10,
  },
}));

export const SocialsRightItems = styled(Box)(({ theme }) => ({
  width: "30%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  gap: 10,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    width: "fit-content",
  },
}));

export const SocialsRightImage = styled("img")(({ theme }) => ({
  height: 188,
  width: 188,
  [theme.breakpoints.down("sm")]: {
    height: 79,
    width: 79,
  },
}));

export const SocialsRightDiscord = styled("img")(({ theme }) => ({
  height: 125,
  width: 173,
  [theme.breakpoints.down("sm")]: {
    width: 91,
    height: 65,
  },
}));

export const SocialsTitle = styled(Typography)(({ theme }) => ({
  color: colors.white,
  fontSize: 32,
  lineHeight: 1.4,
  [theme.breakpoints.down("sm")]: {
    fontSize: 26,
  },
}));

export const CardDescription = styled(Typography)(() => ({
  fontSize: 16,
  color: "#B3B3B3",
  lineHeight: 1.5,
}));

export const SocialButtonsWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: 10,
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
}));

export const SocialsButton = styled("a")(() => ({
  width: 144,
  height: 45,
  background: "#FFFFFF",
  borderRadius: 50,
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  color: colors.black,
  display: "flex",
  cursor: "pointer",
  textDecoration: "none",
  zIndex: 100,
  transition: "all 0.5s ease-in-out", // Add transition property

  "&:hover": {
    backgroundColor: colors.main,
  },
}));

export const GridLinesBottom = styled("img")(() => ({
  height: "100%",
  bottom: "-50%",
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, 0)",
  zIndex: -1,
}));

export const GridLinesTop = styled("img")(() => ({
  height: "100%",
  bottom: 0,
  position: "absolute",
  top: "-50%",
  left: "50%",
  transform: "translate(-50%, 0) rotate(180deg)", // Rotate the image by 180 degrees
  zIndex: -1,
}));

const HighlightStyle: any = {
  WebkitFilter: "blur(70px)",
  filter: "blur(70px)",
  zIndex: 1,
  position: "absolute",
  overflow: "hidden",
  transition: "filter .2s ease-in-out",
  willChange: "transform",
};

export const Highlights = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: -150,
  zIndex: 0,
  left: "10%",

  [theme.breakpoints.down("sm")]: {
    top: -400,
    WebkitFilter: "blur(100px)",
    filter: "blur(100px)",
    left: -70,
  },
}));

export const Highlight1 = styled(Box)(({ theme }) => ({
  ...HighlightStyle,
  background:
    "radial-gradient(50% 50% at 50% 50%, #FF8D5C 0%, rgba(171,171,171, 0) 100%)",
  height: 171,
  width: 976,
  WebkitFilter: "blur(100px)",
  filter: "blur(100px)",

  [theme.breakpoints.down("sm")]: {
    width: 485,
    height: 485,
    left: 0,
    top: 0,
    WebkitFilter: "blur(100px)",
    filter: "blur(100px)",
  },
}));
