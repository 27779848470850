import React, { useState } from "react";
import { MainContainer } from "./styled";
import LeadingTheFrontier from "./LeadingTheFrontier";
import IntroducingPalmswapV2 from "./IntroducingPalmswapV2";

const CenterSection = () => {
  const [, setShowFront] = useState(true);

  const handleMouseMove = (e) => {
    const { clientX } = e;
    const halfScreenWidth = window.innerWidth / 2;
    const newShowFront = clientX < halfScreenWidth;
    setShowFront(newShowFront);
  };

  return (
    <MainContainer onMouseMove={handleMouseMove}>
      <IntroducingPalmswapV2 />
      <LeadingTheFrontier />
    </MainContainer>
  );
};

export default CenterSection;
