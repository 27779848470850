import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import logo from "assets/HeaderFoxify.svg";
import CustomMenuItem from "./NavItem";
import { NAVS } from "config/navs";
import TradeNowButton from "../TradeNowButton";
import {
  BodyMenuSection,
  Center,
  CloseButton,
  CloseIcon,
  HeaderContainer,
  HeaderLogo,
  LeftSide,
  MenuButton,
  MenuIcon,
  MobileMenuContainer,
  NavsContainer,
  RightSide,
  StyledDrawer,
  TopMenuSection,
  LogoWrapper,
  ButtonContainer,
} from "./styled";
import MobileMenu from "./MobileNavs";

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(1120));
  // const isLargeScreen = useMediaQuery(theme.breakpoints.up(1200));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <HeaderContainer>
        <NavsContainer>
          <LeftSide>
            <LogoWrapper>
              <HeaderLogo onClick={() => navigate("/")} src={logo} alt="logo" />
            </LogoWrapper>
          </LeftSide>

          <Center>
            {!(isSmallScreen || isMediumScreen) &&
              NAVS.map((nav: any, i: any) => (
                <CustomMenuItem key={i} nav={nav} />
              ))}
          </Center>
          <RightSide>
            {!(isSmallScreen || isMediumScreen) ? (
              <TradeNowButton styles={{ margin: "10px 0px" }} />
            ) : (
              <ButtonContainer>
                <TradeNowButton styles={{ margin: "10px 0px" }} />

                <MenuButton
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                  onClick={handleClick}
                >
                  <MenuIcon />
                </MenuButton>
              </ButtonContainer>
            )}
          </RightSide>
          {(isSmallScreen || isMediumScreen) && (
            <>
              <StyledDrawer
                anchor="right"
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <TopMenuSection>
                  <Box component="img" src={logo} height="31px" />

                  <CloseButton onClick={handleClose}>
                    <CloseIcon />
                  </CloseButton>
                </TopMenuSection>
                <BodyMenuSection>
                  <MobileMenuContainer>
                    {NAVS.map((nav, i) => (
                      <MobileMenu key={i} nav={nav} />
                    ))}
                  </MobileMenuContainer>
                </BodyMenuSection>
              </StyledDrawer>
            </>
          )}
        </NavsContainer>
      </HeaderContainer>
    </>
  );
};

export default Header;
