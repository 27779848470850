import { Box, keyframes, styled, Typography } from "@mui/material";
import LinkButton from "components/Common/LinkButton";
import { CardMuted } from "components/Common/styled";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 70,
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 18,
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    gap: 10,
  },
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: 18,
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

export const InfoCardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  height: 480,
  alignItems: "space-between",
  gap: 18,

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    gap: 10,
    height: "100%",
    padding: "0 24px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
    height: "100%",
    padding: 0,
  },
}));

export const Bold = styled(Typography)(() => ({
  fontWeight: "bold",
}));

export const TokenCard = styled(CardMuted)(() => ({
  cursor: "pointer",
  width: "100%",
  height: 180,
  display: "flex",
  gap: 20,
  flexDirection: "column",
  justifyContent: "space-between",
  padding: 20,
  "&:hover": {
    "& .MuiButtonBase-root": {
      opacity: 1,
    },
  },
}));

export const TokenCardRow = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const TokenImg = styled("img")(() => ({
  width: 35,
  height: 35,
}));

export const TokenLink = styled(LinkButton)(() => ({
  "&.MuiButtonBase-root": {
    minHeight: 40,
    height: 40,
    opacity: 0,
  },
}));

export const LinkBtn = styled(LinkButton)(() => ({
  "&.MuiButtonBase-root": {
    minHeight: 40,
    height: 40,
  },
}));

export const TokenCardTop = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));

export const TokenName = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const TokenStats = styled(Box)({
  display: "flex",
  gap: 2,
  flexDirection: "column",
});

export const TokenStatContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const WhiteText = styled(Typography)({
  fontSize: 18,
  color: colors.white,
});

export const TokenPrice = styled(Typography)({
  fontSize: 24,
  color: colors.white,
});

export const Card = styled(Box)(({ theme }) => ({
  backgroundColor: "#090909",
  width: "100%",
  display: "flex",
  gap: 20,
  flexDirection: "column",
  border: `1px solid ${colors.border}`,
  borderRadius: 25,
  padding: 20,

  cursor: "pointer",
  transition: "border-color 0.3s ease-in-out", // Add transition property

  "&:hover": {
    borderColor: "rgba(246, 107, 49, 0.6)",
  },
}));
export const CardWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",

  "&:first-child": {
    order: 1,
  },

  "&:last-child": {
    order: 3,
  },

  [theme.breakpoints.down("lg")]: {
    paddingLeft: 0,
    width: "100%",
    gap: 10,

    "&:first-child": {
      order: 2,
    },

    "&:last-child": {
      order: 3,
    },
  },
}));

export const CenterSection = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: 800,
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  order: 2,

  [theme.breakpoints.down("lg")]: {
    paddingLeft: 0,
    order: 1,
    width: "100%",
    height: 400,
  },
}));

export const CardHead = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: 24,
  fontWeight: "bold",
  gap: 10,
}));

export const CardDesctiptionContainer = styled(Box)(() => ({
  height: "100%",
  gap: 15,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const CenterLogo = styled("img")(() => ({
  height: 72,
}));

export const Lines = styled("img")(({ theme }) => ({
  height: 400,
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const LogoBackground1 = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: 170,
  height: 170,
  borderRadius: "50%",
  background: "#1b1b1b",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${colors.border}`,
  display: "flex",
  marginLeft: -20,
}));

export const LogoBackground2 = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: 170,
  height: 170,
  borderRadius: "50%",
  background: "rgba(255,255,255,0.02)",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${colors.border}`,
  animation: `${pulseAnimation} 4s ease-out infinite 1s`, // 1.5s delay
  marginLeft: -20,
}));

export const LogoBackground3 = styled(Box)(({ theme }) => ({
  position: "absolute",
  marginLeft: -20,
  width: 170,
  height: 170,
  borderRadius: "50%",
  background: "rgba(255,255,255,0.02)",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${colors.border}`,
  opacity: 1,
  animation: `${pulseAnimation} 4s ease-out infinite`,
}));

const pulseAnimation = keyframes`

100% {
  opacity: 0;
  transform: scale(2);
}
`;
