import React from "react";
import { ButtonProps } from "@mui/material";
import {
  ArrowsBox,
  LinkButtonIcon,
  LinkButtonIconSecond,
  LinkButtonStyled,
} from "./styled";

interface LinkButtonProps extends ButtonProps {
  href?: string;
  target?: string;
  isWhiteArrow?: boolean;
}

const LinkButton: React.FC<LinkButtonProps> = ({
  href,
  target,
  isWhiteArrow,
  ...props
}) => {
  return (
    <a href={href} target={target} style={{ textDecoration: "none" }}>
      <LinkButtonStyled
        {...props}
        variant="contained"
        endIcon={
          <ArrowsBox className="arrows-box">
            <LinkButtonIcon isWhiteArrow={isWhiteArrow} />
            <LinkButtonIconSecond isWhiteArrow={isWhiteArrow} />
          </ArrowsBox>
        }
      ></LinkButtonStyled>
    </a>
  );
};

export default LinkButton;
