import React from "react";
import LinkButton from "components/Common/LinkButton";
import { Link } from "react-router-dom";

import {
  ButtonsContainer,
  Description,
  ExchangeImg,
  Highlight1,
  Highlight2,
  Highlight3,
  Highlight4,
  HighlightsContainer,
  LeftTop,
  MainContainer,
  RightBottom,
  Title,
} from "./styled";

import LiquidityHeader from "assets/LiquidityHeader.png";

import styled from "@emotion/styled";

export const StyledLink = styled(Link)({
  textDecoration: "none",
});

const TopSection = () => {
  return (
    <MainContainer>
      <LeftTop>
        <Title>
          Add Liquidity and
          <br />
          earn up to 100% APR
          <br />
          in $USDT
        </Title>
        <Description>
          Add liquidity to Palmswap V2 and earn rewards in stablecoins. Enjoy
          the simplicity of single-asset pools with zero impermanent loss and
          generate a perpetual income stream on your liquidity deposits.
        </Description>
        <ButtonsContainer>
          <LinkButton href="https://perp.foxify.trade" target="_blank">
            Add Liquidity
          </LinkButton>
        </ButtonsContainer>
      </LeftTop>
      <RightBottom>
        <ExchangeImg src={LiquidityHeader} alt="LiquidityHeader" />
        <HighlightsContainer>
          <Highlight1 />
          <Highlight2 />
          <Highlight3 />
          <Highlight4 />
        </HighlightsContainer>
      </RightBottom>
    </MainContainer>
  );
};

export default TopSection;
