import {
  CardDescription,
  GridLinesTop,
  GridLinesBottom,
  SocialButtonsWrapper,
  SocialsButton,
  SocialsContainer,
  SocialsLeft,
  SocialsRight,
  SocialsTitle,
  SocialsRightItems,
  SocialsRightImage,
  SocialsRightDiscord,
  Highlight1,
  Highlights,
} from "./styled";

import lines from "assets/linesGrid.png";
import discordLogo from "assets/discordLogo.png";
import telegramLogo from "assets/telegramLogo.svg";
import xLogo from "assets/xLogo.svg";

const Socials = () => {
  return (
    <SocialsContainer>
      <Highlights>
        <Highlight1 />
      </Highlights>
      <GridLinesTop src={lines} />
      <SocialsLeft>
        <SocialsTitle>
          Join our community to be a part of the evolution
        </SocialsTitle>
        <CardDescription>
          Connect with fellow traders, access exclusive updates, and participate
          in engaging AMAs. Become part of a thriving trading community where
          learning and growth are at the forefront.
        </CardDescription>
        <SocialButtonsWrapper>
          <SocialsButton
            href="https://discord.com/invite/foxifytrade"
            target="_blank"
          >
            Join Discord
          </SocialsButton>
          <SocialsButton
            href="https://t.me/foxify_trade"
            target="_blank"
          >
            Join Telegram
          </SocialsButton>
          <SocialsButton href="https://twitter.com/foxifytrade" target="_blank">
            Join X.com
          </SocialsButton>
        </SocialButtonsWrapper>
        <CardDescription>
          20,093 community members and counting!
        </CardDescription>
      </SocialsLeft>
      <SocialsRight>
        <SocialsRightItems>
          <SocialsRightImage src={telegramLogo} />
          <SocialsRightDiscord src={discordLogo} />
        </SocialsRightItems>
        <SocialsRightItems>
          <SocialsRightImage src={xLogo} />
        </SocialsRightItems>
      </SocialsRight>
      <GridLinesBottom src={lines} />
    </SocialsContainer>
  );
};

export default Socials;
