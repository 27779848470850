import { Box, styled, Typography } from "@mui/material";
import { colors } from "config/colors";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

export const MoreIcon = styled(ExpandMoreRoundedIcon)({
  fontSize: 18,
  color: colors.white,
  cursor: "pointer",
});

export const LessIcon = styled(ExpandLessRoundedIcon)({
  fontSize: 18,
  color: colors.white,
  cursor: "pointer",
});

export const FooterWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  background: "rgba(255,255,255, 0.02)",
  marginTop: 150,
  zIndex: 100,
  marginLeft: "auto",
  marginRight: "auto",
  borderTop: `1px solid ${colors.border}`,
}));

export const FooterBottomWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginLeft: "auto",
  marginRight: "auto",
  borderTop: `1px solid ${colors.border}`,
}));

export const FooterContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  padding: "50px 200px 36px 200px",
  maxWidth: 1550,
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.down("md")]: {
    padding: "24px 60px 28px 60px",
    minHeight: 225,
  },
  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px 28px 16px",
    minHeight: 225,
  },
}));

export const FooterBottom = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0px 200px 0px 200px",
  maxWidth: 1550,
  marginLeft: "auto",
  height: 70,
  marginRight: "auto",
  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px 28px 16px",
    height: 94,
  },
}));

export const SocialsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: 10,
}));
export const SocialsIcon = styled("img")({
  height: 20,
  width: 20,
  cursor: "pointer",
});
export const GridLines = styled("img")(() => ({
  width: "100%",
  bottom: 0,
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, 0)",
  zIndex: -1,
}));

export const LinksContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  flexDirection: "row",
  zIndex: 10,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    gap: 30,
  },
}));

export const InfoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const MainLinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  width: 300,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    gap: 10,
    height: 200,
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const LinksWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 20,
  width: "100%",
  alignItems: "center",
  height: "100%",
  cursor: "pointer",
  justifyContent: "flex-start",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    gap: 10,
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "center",
  },
}));

export const Links = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 20,
  width: "100%",
  alignItems: "center",
  cursor: "pointer",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    gap: 10,
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "center",
  },
}));

export const LinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 100,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    gap: 10,
    flexDirection: "column",
  },
}));

export const Logo = styled("img")({
  width: 68,
  height: 52,
});

// TODO: delete any
const InactiveTextStyle: any = {
  fontSize: 12,
  color: colors.white,
  lineHeight: 1.5,
};

export const Description = styled(Typography)({
  ...InactiveTextStyle,
});

export const Link = styled(Typography)(({ theme }) => ({
  color: colors.white,
  fontSize: 14,
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

export const LinkAllRights = styled(Typography)(({ theme }) => ({
  color: colors.inactive,
  fontSize: 14,
  [theme.breakpoints.down("sm")]: {},
}));

export const PolicyTermsContainer = styled(Box)({
  display: "flex",
  gap: 10,
});

export const PolicyTermsLink = styled("a")({
  ...InactiveTextStyle,
  cursor: "pointer",
  textDecoration: "underline",
});

export const LinkGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  width: "fit-content",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const LinkGroupNameContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const LinkGroupName = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  color: colors.inactive,
});

export const LinkGroupBody = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

export const LinkGroupBodyItem = styled("a")({
  ...InactiveTextStyle,
  textDecoration: "none",
  cursor: "pointer",
});

const HighlightStyle: any = {
  WebkitFilter: "blur(70px)",
  filter: "blur(70px)",
  zIndex: 1,
  position: "absolute",
  overflow: "hidden",
  transition: "filter .2s ease-in-out",
  willChange: "transform",
};

export const Highlights = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: -450,
  zIndex: 1,
  left: "10%",
  [theme.breakpoints.down("sm")]: {
    top: 0,
    WebkitFilter: "blur(100px)",
    filter: "blur(100px)",
  },
}));

export const Highlight = styled(Box)(({ theme }) => ({
  ...HighlightStyle,
  background:
    "radial-gradient(50% 50% at 50% 50%, #3D1504 0%, rgba(0, 0, 0, 0) 100%)",
  height: 599,
  width: 1168,
  WebkitFilter: "blur(100px)",
  filter: "blur(100px)",

  [theme.breakpoints.down("sm")]: {
    width: 374,
    height: 307,
    left: -30,
    top: -30,
    WebkitFilter: "blur(100px)",
    filter: "blur(100px)",
  },
}));
