import React from "react";
import LinkButton from "components/Common/LinkButton";

import {
  ButtonsContainer,
  Description,
  LeftTop,
  Title,
  Background,
  BackgroundImage,
  Highlight2,
  Highlights,
  Background2,
  MaxTradeSize,
  Section,
  FundedLogo,
  StyledBr,
} from "./styled";
import backgroundImage from "assets/background.svg";
import fundedLogoImage from "assets/funded-logo.png";
import Carousell from "../CenterSection/Carousell";

const TopSection = () => {
  return (
    <>
      <Section>
        <LeftTop>
          <Background2 />
          <Background>
            <BackgroundImage src={backgroundImage} />
          </Background>
          <Highlights>
            <Highlight2 />
          </Highlights>
          <FundedLogo src={fundedLogoImage} alt="funded logo" />
          <Title>
            Trade Crypto to start your journey to{" "}
            <MaxTradeSize>$250,000</MaxTradeSize> funded trades!
          </Title>
          <Description>
            Get FUNDED in 7 days, 80% profit share, no up front fees.
            <StyledBr /> Instant withdrawals at the end of your challenge.
          </Description>
          <ButtonsContainer>
            <LinkButton
              href="https://perp.foxify.trade/#/funded/start"
              target="_blank"
            >
              Start your challenge
            </LinkButton>
          </ButtonsContainer>
        </LeftTop>
      </Section>
      <Carousell />
    </>
  );
};

export default TopSection;
