import { Box, styled } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import LinkButton from "components/Common/LinkButton";

export const Container = styled(Box)({
  marginTop: 120,
  paddingTop: 50,
  paddingBottom: 50,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(180deg, #fa6a33 0%, rgb(204, 52, 14) 100%)",
});

export const Title = styled(Box)({
  fontSize: 32,
  color: "white",
  fontWeight: 700,
  marginBottom: 50,
});

export const FundedLevels = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 12,

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    gap: 30,
    width: "100%",
    padding: "0 40px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 30,
    width: "100%",
    padding: "0 16px",
  },
}));

export const FundedLevel = styled(Box)(({ theme }) => ({
  minWidth: 302,
  padding: 15,
  borderRadius: 25,
  backgroundColor: "rgba(0, 0, 0, 0.08)",
}));

export const FundedLevelRank = styled(Box)({
  width: "fit-content",
  height: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  borderRadius: 500,
  border: "1px solid rgba(0, 0, 0, 0.08)",
  color: "white",
  fontSize: 12,
  fontWeight: 300,
  paddingLeft: 10,
  paddingRight: 10,
  gap: 4,
});

export const FundedLevelMaxTradeSize = styled(Box)({
  fontSize: 36,
  fontWeight: 700,
  color: "white",
});

export const FundedLevelLeverage = styled(Box)({
  fontSize: 14,
  color: "white",
});

export const Divider = styled(Box)({
  marginTop: 10,
  borderTop: "1px solid rgba(0, 0, 0, 0.08)",
  width: "100%",
});

export const FundedLevelDetails = styled(Box)({
  display: "flex",
  flexDirection: "column",
  paddingTop: 14,
  paddingBottom: 14,
  gap: 14,
});

export const FundedLevelDetail = styled(Box)({
  display: "flex",
  alignItems: "center",
  color: "white",
  fontSize: 14,
  gap: 10,
});

export const CheckIconContainer = styled(Box)({
  width: 25,
  height: 25,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.05)",
  borderRadius: 500,
  border: "1px solid rgba(0, 0, 0, 0.08)",
  fontSize: 16,
  marginRight: 4,
});

export const Check = styled(CheckIcon)({
  width: 16,
  height: 16,
});

export const StyledLinkButton = styled(LinkButton)({
  width: "100%",
});
