import { Button, styled } from "@mui/material";
import { colors } from "config/colors";

export const StyledButton = styled(Button)(({ theme }) => ({
  border: "none",
  background: colors.main,
  color: colors.black,
  whiteSpace: "nowrap",
  height: 37,
  width: 126,
  cursor: "pointer",
  fontSize: 14,
  borderRadius: 500,
  fontWeight: 500,
  transition: "background 0.5s ease",

  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
  ":hover": {
    background: colors.white,
  },
}));

export const ButtonContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "min-content !important",
  display: "inline-block",
}));

export const DropdownContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "120%",
  zIndex: 100,
  left: "20%",
  transform: "translate(-50%, 0)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const Dropdown = styled("div")(({ theme }) => ({
  paddingTop: 6,
  paddingBottom: 6,
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  background: "#141416",
  borderRadius: 7,
}));

export const DropdownItem = styled("a")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  fontSize: 15,
  padding: "6px 12px",
  textDecoration: "none",
  ":hover": {
    opacity: 0.6,
  },
}));

export const DropdownItemHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 6,
}));

export const DropdownItemHeaderImg = styled("img")(({ theme }) => ({
  width: 15,
  height: 15,
}));

export const DropdownItemHeaderText = styled("div")(({ theme }) => ({
  color: "#fff",
  fontWeight: 300,
  whiteSpace: "nowrap",
  lineHeight: 2,
}));

export const DropdownItemText = styled("div")(({ theme }) => ({
  color: "#727272",
  whiteSpace: "nowrap",
  fontSize: 13,
  lineHeight: "22px",
  fontWeight: 300,
}));

export const Arrow = styled("div")(({ theme }) => ({
  width: 0,
  height: 0,
  borderLeft: "7px solid transparent",
  borderRight: "7px solid transparent",
  borderBottom: "7px solid #141416",
}));
