import React from "react";
import exchangeImage from "assets/ExchangeImage.png";
import mobileExchangeImage from "assets/Mobile_exchange.png";
import CenterSection from "./CenterSection";
import { CenterBottom, HomePageContainer } from "./styled";
import TopSection from "./TopSection";
import { MainContainer } from "./CenterSection/styled";
import FoxifyProtocoll from "./CenterSection/FoxifyProtocoll";
import BottomCarousell from "./CenterSection/BottomCaroussel";
import Socials from "./CenterSection/Socials";
import FundingLevels from "./FundingLevels";
import FuturesTrading from "./FuturesTrading";
import Funded from "./CenterSection/Funded";
import { useMedia } from "react-use";
import {
  ExchangeImageContainer,
  ExchangeImg,
  ExchangeImgMobile,
  RightBottom,
} from "./TopSection/styled";
import Stats from "./CenterSection/Stats";

const HomePage = () => {
  const isMobile = useMedia("(max-width: 1100px)");
  return (
    <HomePageContainer>
      <TopSection />
      <Stats />
      <Funded />
      <FundingLevels />
      <FuturesTrading />
      <MainContainer>
        <RightBottom>
          {isMobile ? (
            <ExchangeImageContainer>
              <ExchangeImgMobile
                src={mobileExchangeImage}
                alt="exchangeScreen"
              />
            </ExchangeImageContainer>
          ) : (
            <ExchangeImageContainer>
              <ExchangeImg src={exchangeImage} alt="exchangeScreen" />
            </ExchangeImageContainer>
          )}
        </RightBottom>
      </MainContainer>
      <CenterBottom>
        <CenterSection />
      </CenterBottom>
      <CenterBottom>
        <MainContainer>
          <FoxifyProtocoll />
          <Socials />
        </MainContainer>
      </CenterBottom>
      <BottomCarousell />
    </HomePageContainer>
  );
};

export default HomePage;
