import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./components/Header/index";
import Footer from "./components/Footer/index";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./config/theme";
import "./index.css";
import { ApolloProvider } from "@apollo/react-hooks";
import { wssClient } from "api/graph";
import HomePage from "components/HomePage";
import LiquidityPage from "components/LiquidityPage";
import TermsPage from "components/TermsPage";
import PrivacyPolicyPage from "components/PrivacyPolicyPage";
import ReferralAgreementPage from "components/ReferralAgreementPage";
import { WeberForm } from "components/WeberForms";
import ConfirmPage from "components/ConfirmPage";

ReactDOM.render(
  <React.StrictMode>
    <WeberForm />
  </React.StrictMode>,
  document.getElementById("weberForms")
);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={wssClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/terms_of_use" element={<TermsPage />} />
            <Route path="/liquidity" element={<LiquidityPage />} />
            <Route path="/confirm" element={<ConfirmPage />} />
            <Route
              path="/referral_agreement"
              element={<ReferralAgreementPage />}
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
