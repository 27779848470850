import { Box, styled } from "@mui/material";

export const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 150,
  padding: "50px 200px 0px 200px",
  zIndex: 1,
  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px 0px 16px",
    gap: 100,
  },
}));
