import { Box, styled, Typography } from "@mui/material";
import { CardMuted } from "components/Common/styled";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 18,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: 10,
  },
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: 50,
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    gap: 18,
  },
}));

export const Bold = styled(Typography)(() => ({
  fontWeight: "bold",
}));

export const TokenCard = styled(CardMuted)(({ borderColor }) => ({
  cursor: "pointer",
  height: 155,
  display: "flex",
  gap: 20,
  flexDirection: "column",
  boxSizing: "border-box",
  justifyContent: "space-between",
  background: "#090909",
  padding: 20,
  border: `1px solid ${borderColor}`,
  // boxShadow: `0px 1px 3px rgba(0, 0, 0, 0.2), 0px 0px 5px ${borderColor}`,
  transition: "box-shadow 0.3s ease-in-out, transform 0.3s ease", // Ensure transform is included in the transition

  "&:hover": {
    boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.2), 0px 0px 10px ${borderColor}`,
    transform: "translateY(-2px)", // Move the card up by 5px

    "& .MuiButtonBase-root": {
      opacity: 1,
    },
  },
}));

export const Card = styled(Box)(() => ({
  cursor: "pointer",
  height: 155,
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 15,
  background: "transparent",
  maxWidth: 390,
}));

export const CardSymbol = styled("img")(() => ({
  width: 40,
  height: 40,
}));

export const CardTitleWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
}));

export const CardTitle = styled(Typography)(() => ({
  fontSize: 28,
}));

export const SoonReleased = styled(Box)(() => ({
  background: "rgba(246, 107, 48, 0.1)",
  color: "#F66B30",
  padding: "5px 10px 5px 10px",
  borderRadius: 5,
}));

export const CardDescription = styled(Typography)(() => ({
  fontSize: 16,
  textAlign: "center",
  color: "#B3B3B3",
}));

export const CarousellWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  gap: 20,
  marginTop: 150,
  display: "flex",
  flexDirection: "column",
  position: "relative",
  [theme.breakpoints.down("sm")]: {},
}));

export const CarousellContainer = styled(Box)(({ theme }) => ({
  color: "#000000",
  display: "flex",
  alignItems: "center",
  gap: 20,
  width: "100%",
}));

export const CarousellLogoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${colors.border}`,
  borderRadius: 25,
  width: 80,
  height: 80,
}));

export const CarousselBoxShadow = styled(Box)(({ theme, boxShadow }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.2), 0px 0px 20px ${boxShadow}`,
  borderRadius: "50%",
}));

export const CarousellTextItem = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  display: "inline-block",
  textAlign: "center",
  color: "black",
  whiteSpace: "nowrap",
  fontWeight: "bold",
}));

export const CarousellLogo = styled("img")(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: "50%", // Make it circular
  objectFit: "cover", // Adjust object-fit as needed
}));

export const LogoContainer = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: `${colors.main}`,
  borderRadius: 25,
  width: 120,
  height: 120,
  zIndex: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
export const Logo = styled("img")(() => ({
  width: 78,
}));
