export const footerMenu = {
  About: [
    { Documentation: "https://docs.palmswap.org/" },
    { "FAQ's": "/faq" },
    { "Brand Guidelines": "/guidelines" },
    { "Privacy Policy": "/privacy" },
    { "Referral Agreement": "/referral_agreement" },
    { "Terms of Use": "/terms_of_use" },
  ],
  Developers: [
    { Github: "#" },
    { "Smart Contracts": "#" },
    { "Bug Bounty": "#" },
  ],
  Careers: [
    { "Job Board": "https://angel.co/company/palmswap-1/jobs" },
    { "Write Us": "mailto:office@palmswap.org" },
  ],
  "Social Network": [
    { Discord: "https://discord.com/invite/MQeV5yr3sy" },
    { Medium: "https://medium.com/@Palmswap" },
    { Twitter: "https://twitter.com/Palmswaporg" },
    { "Telegram Chat": "https://t.me/Palmswapchat" },
    { "Telegram Ann": "https://t.me/PalmswapAnn" },
  ],
};

export const footerMenu2 = [
  {
    name: "Products",
    links: [
      {
        name: "Exchange",
        link: "perp.foxify.trade",
      },
      {
        name: "T2E",
        link: "https://perp.foxify.trade/#/event",
      },
      {
        name: "Buy",
        link: "https://perp.foxify.trade/#/buy",
      },
    ],
  },
  {
    name: "Resources",
    links: [
      {
        name: "Documentation",
        link: "https://docs.foxify.trade/",
      },
      {
        name: "Press",
        link: "#",
      },
    ],
  },
  {
    name: "About",
    links: [
      {
        name: "GitBook",
        link: "https://docs.foxify.trade/",
      },

      {
        name: "Media Kit",
        link: "#",
      },
    ],
  },
];
