import { Box, styled } from "@mui/material";
import { colors } from "config/colors";

export const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  zIndex: 20,
  marginTop: 120,
  padding: "0 60px",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    padding: "0 40px",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: "0 16px",
  },
}));

export const StatsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 140,
  borderRadius: 25,
  backgroundColor: "#09090B",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  border: "1px solid",
  borderColor: colors.border,
  [theme.breakpoints.down("sm")]: {
    borderRadius: "25px 25px 0 0", // Top left and top right
  },
}));

export const StatsContainer2 = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 140,
  borderRadius: 25,
  backgroundColor: "#09090B",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  border: "1px solid",
  borderColor: colors.border,
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0 0 25px 25px", // Bottom left and bottom right
  },
}));

export const StatContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  textAlign: "center",
  height: "99",
  borderRight: "1px solid",
  borderRightColor: colors.border,
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    width: "100%",
  },
  ":last-child": {
    borderRight: "none",
  },
}));
