import {
  CarousellWrapper,
  CarousellContainer,
  CarousellLogo,
  CarousellLogoWrapper,
  CarousselBoxShadow,
  LogoContainer,
  Logo,
} from "./styled";

import Marquee from "react-fast-marquee";

import logo from "assets/blacklogo.svg";

import ethIcon from "assets/eth.svg";
import btcIcon from "assets/bitcoin.svg";
import bnbIcon from "assets/bnb.svg";
import xrpIcon from "assets/xrp.svg";
import linkIcon from "assets/link.svg";
import solIcon from "assets/sol.svg";
import maticIcon from "assets/matic.svg";
// ... (your imports remain the same)

const CarousellLogos = [
  { logo: ethIcon, boxShadow: "rgba(98, 126, 235, 1)" },
  { logo: btcIcon, boxShadow: "rgba(247, 147, 26, 1)" },
  { logo: bnbIcon, boxShadow: "rgba(240, 185, 11, 1)" },
  { logo: xrpIcon, boxShadow: "rgba(255, 255, 255, 1)" },
  { logo: linkIcon, boxShadow: "rgba(43, 91, 218, 1)" },
  { logo: solIcon, boxShadow: "rgba(110, 137, 212, 1)" },
  { logo: maticIcon, boxShadow: "rgba(130, 71, 229, 1)" },
  { logo: ethIcon, boxShadow: "rgba(98, 126, 235, 1)" },
  { logo: btcIcon, boxShadow: "rgba(247, 147, 26, 1)" },
  { logo: bnbIcon, boxShadow: "rgba(240, 185, 11, 1)" },
  { logo: xrpIcon, boxShadow: "rgba(255, 255, 255, 1)" },
  { logo: linkIcon, boxShadow: "rgba(43, 91, 218, 1)" },
  { logo: solIcon, boxShadow: "rgba(110, 137, 212, 1)" },
  { logo: maticIcon, boxShadow: "rgba(130, 71, 229, 1)" },
  { logo: ethIcon, boxShadow: "rgba(98, 126, 235, 1)" },
  { logo: btcIcon, boxShadow: "rgba(247, 147, 26, 1)" },
  { logo: bnbIcon, boxShadow: "rgba(240, 185, 11, 1)" },
  { logo: xrpIcon, boxShadow: "rgba(255, 255, 255, 1)" },
  { logo: linkIcon, boxShadow: "rgba(43, 91, 218, 1)" },
  { logo: solIcon, boxShadow: "rgba(110, 137, 212, 1)" },
  { logo: maticIcon, boxShadow: "rgba(130, 71, 229, 1)" },
];

const BottomCarousell = () => {
  return (
    <CarousellWrapper>
      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>
      <Marquee speed={50}>
        <CarousellContainer>
          {CarousellLogos.map((logoObj, index) => (
            <CarousellLogoWrapper key={index}>
              <CarousselBoxShadow boxShadow={logoObj.boxShadow}>
                <CarousellLogo src={logoObj.logo} />
              </CarousselBoxShadow>
            </CarousellLogoWrapper>
          ))}
        </CarousellContainer>
      </Marquee>
      <Marquee speed={50}>
        <CarousellContainer style={{ marginLeft: "-80px" }}>
          {CarousellLogos.map((logoObj, index) => (
            <CarousellLogoWrapper key={index}>
              <CarousselBoxShadow boxShadow={logoObj.boxShadow}>
                <CarousellLogo src={logoObj.logo} />
              </CarousselBoxShadow>
            </CarousellLogoWrapper>
          ))}
        </CarousellContainer>
      </Marquee>
      <Marquee speed={50}>
        <CarousellContainer>
          {CarousellLogos.map((logoObj, index) => (
            <CarousellLogoWrapper key={index}>
              <CarousselBoxShadow boxShadow={logoObj.boxShadow}>
                <CarousellLogo src={logoObj.logo} />
              </CarousselBoxShadow>
            </CarousellLogoWrapper>
          ))}
        </CarousellContainer>
      </Marquee>
    </CarousellWrapper>
  );
};

export default BottomCarousell;
