import { useState } from "react";
import {
  ExploreMenuItem,
  ExploreMenuItemDescription,
  ExploreMenuItemHeader,
  ExploreMenuItemHeaderContainer,
  ExploreMenuItemIcon,
  ExploreMenuItemsContainer,
  ExploreMenuItemTag,
  ExploreMenuItemTextContainer,
  LessIcon,
  LinkGroup,
  LinkGroupLinkContainer,
  LinkGroupName,
  LinkGroupNameContainer,
  LinkIcon,
  MoreIcon,
  StyledDescription,
  StyledMenuHeader,
  StyledMenuItem,
  StyledMenuText,
} from "./styled";

import v2 from "assets/exploreV2.svg";
import event from "assets/exploreEvent.svg";
import refferal from "assets/exploreReferral.svg";
import staking from "assets/exploreStaking.svg";
import { Box } from "@mui/material";

const exploreMenu = [
  {
    title: "Trading",
    children: [
      {
        icon: v2,
        title: "Trade",
        description:
          "Start trading on Foxify and experience the future of DeFi trading.",
        tag: "Live",
        route: "https://perp.foxify.trade",
      },
    ],
  },
  {
    title: "Earn",
    children: [
      {
        icon: staking,
        title: "Staking",
        description: "Stake your tokens and earn more.",
        tag: "Coming Soon",
        // route: "https://perp.foxify.trade/#/buy",
      },

      {
        icon: refferal,
        title: "Referral Program",
        description:
          "Invite friends and earn up to 10% of their trading fees. ",
        tag: "Live",
        route: "https://perp.foxify.trade/#/referral",
      },
    ],
  },
  {
    title: "Events",
    children: [
      {
        icon: event,
        title: "T2E",
        description: "Earn Rewards with every trade you open and close.",

        tag: "Coming Soon",
        route: "https://perp.foxify.trade/#/event",
      },
    ],
  },

  {
    title: "Buy FOX",
    route: "https://app.balancer.fi/#/arbitrum/swap",
  },
];

const ExploreMobileMenu = ({ nav }) => {
  const [open, setOpen] = useState(false);

  return (
    <LinkGroup active={open} inner>
      {nav.children ? (
        <LinkGroupNameContainer onClick={() => setOpen(!open)}>
          <LinkGroupName>{nav.title}</LinkGroupName>
          {!open && <MoreIcon />}
          {open && <LessIcon />}
        </LinkGroupNameContainer>
      ) : (
        <LinkGroupLinkContainer href={nav.route}>
          <LinkGroupName>{nav.title}</LinkGroupName>
          <LinkIcon />
        </LinkGroupLinkContainer>
      )}
      {open && (
        <ExploreMenuItemsContainer>
          {nav.children.map((c, i) => (
            <ExploreMenuItemsContainer>
              <ExploreMenuItem
                disabled={c.tag === "Coming Soon"}
                key={c.title + i}
                href={c.tag === "Coming Soon" ? null : c.route}
              >
                <ExploreMenuItemIcon src={c.icon} />
                <ExploreMenuItemTextContainer>
                  <ExploreMenuItemHeaderContainer>
                    <ExploreMenuItemHeader>{c.title}</ExploreMenuItemHeader>
                    {c.tag && <ExploreMenuItemTag>{c.tag}</ExploreMenuItemTag>}
                  </ExploreMenuItemHeaderContainer>
                  <ExploreMenuItemDescription>
                    {c.description}
                  </ExploreMenuItemDescription>
                </ExploreMenuItemTextContainer>
              </ExploreMenuItem>
            </ExploreMenuItemsContainer>
          ))}
        </ExploreMenuItemsContainer>
      )}
    </LinkGroup>
  );
};

const MobileMenu = ({ nav }) => {
  const [open, setOpen] = useState(false);

  return (
    <LinkGroup active={open}>
      {nav.children || nav.title === "Explore" ? (
        <LinkGroupNameContainer onClick={() => setOpen(!open)}>
          <LinkGroupName>{nav.title}</LinkGroupName>
          {!open && <MoreIcon />}
          {open && <LessIcon />}
        </LinkGroupNameContainer>
      ) : (
        <LinkGroupLinkContainer href={nav.route}>
          <LinkGroupName>{nav.title}</LinkGroupName>
          <LinkIcon />
        </LinkGroupLinkContainer>
      )}
      {open &&
        nav.title === "Explore" &&
        exploreMenu.map((menu) => <ExploreMobileMenu nav={menu} />)}
      {open &&
        nav.title !== "Explore" &&
        nav.children.map((nav: any, i: any) => (
          <StyledMenuItem
            key={i}
            href={nav.route}
            target={nav.target}
            rel={nav.target && "noopener"}
          >
            {nav.icon && (
              <Box
                component="img"
                src={nav.icon}
                alt="icon"
                style={{ width: 22, height: 22 }}
              />
            )}
            <StyledMenuText>
              <StyledMenuHeader>{nav.title}</StyledMenuHeader>
              <StyledDescription>{nav.description}</StyledDescription>
            </StyledMenuText>
          </StyledMenuItem>
        ))}
    </LinkGroup>
  );
};

export default MobileMenu;
