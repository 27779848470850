import React, { useEffect, useRef, useState } from "react";

import { ButtonContainer, StyledButton } from "./styled";


const TradeNowButton = ({ styles }) => {
  const [, setShow] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <ButtonContainer ref={ref}>
      <StyledButton
        color="secondary"
        variant="contained"
        href="https://perp.foxify.trade"
        style={styles}
      >
        Launch App
      </StyledButton>

    </ButtonContainer>
  );
};

export default TradeNowButton;
