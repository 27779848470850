import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { MainContainer, StatContainer, StatsContainer, StatsContainer2 } from "./styled";
import { Description, SectionHeader } from "components/Common/styled";
import { useFeesAndUsers, useHourlyVolume, useTotalVolume } from "api/TradingInfo";
import { formatAmount } from 'api/formatAmount'

const Stats = function () {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const volume_24h = useHourlyVolume();
  const totalVolume = useTotalVolume();
  const feesAndUsers = useFeesAndUsers();

  const [currentVolumeInfo_24h, setCurrentVolumeInfo_24h] = useState(null);
  const [currentVolumeInfo, setCurrentVolumeInfo] = useState(null);
  const [totalFees, setTotalFees] = useState(null);

  useEffect(() => {
  const volumeInfo_24h = formatAmount(volume_24h.data.volume, 30, 0, true);
  setCurrentVolumeInfo_24h(volumeInfo_24h);
  }, [volume_24h.data.volume]);

  useEffect(() => {
    const volumeInfo = formatAmount(totalVolume.data.volume, 30, 0, true);
    setCurrentVolumeInfo(volumeInfo);
  }, [totalVolume.data]);

  useEffect(() => {
    if (
      feesAndUsers.data?.fees
    ) {
      const mintFees = formatAmount(feesAndUsers.data.fees.mint, 30, 0, true);
      const tradingFees = formatAmount(
        feesAndUsers.data.fees.margin,
        30,
        0,
        true
      );

      const total =
        parseFloat(mintFees.replace(",", "")) +
        parseFloat(tradingFees.replace(",", ""));
        setTotalFees(total.toLocaleString('en-US'));
      }
  }, [
    volume_24h.data.volume,
    totalVolume.data.volume,
    feesAndUsers.data?.fees,
  ]);

  if (isSmallScreen)
    return (
      <MainContainer>
        <StatsContainer>
          <StatContainer>
          <Description>
              Total Trading Volume
            </Description>
            <SectionHeader>
              ${currentVolumeInfo || 0}
            </SectionHeader>
          </StatContainer>
          <StatContainer>
          <Description>
              Todays Trading Volume
            </Description>
            <SectionHeader>
              ${currentVolumeInfo_24h || 0}
            </SectionHeader>
          </StatContainer>
        </StatsContainer>
        <StatsContainer2>
          <StatContainer>
          <Description>
              Total Fees
            </Description>
            <SectionHeader>
              ${totalFees || 0}
            </SectionHeader>
          </StatContainer>
          <StatContainer>
           <Description>
              Total Users
            </Description>
            <SectionHeader>
              {feesAndUsers.data?.users.uniqueCountCumulative || 0}
            </SectionHeader>
          </StatContainer>
        </StatsContainer2>
      </MainContainer>
    );


  return (
    <MainContainer>
      <StatsContainer>
      <StatContainer>
      <Description>
          Total Trading Volume
        </Description>
        <SectionHeader>
          ${currentVolumeInfo || 0}
        </SectionHeader>
      </StatContainer>
      <StatContainer>
      <Description>
          Todays Trading Volume
        </Description>
        <SectionHeader>
          ${currentVolumeInfo_24h || 0}
        </SectionHeader>
      </StatContainer>
      <StatContainer>
      <Description>
          Total Fees
        </Description>
        <SectionHeader>
          ${totalFees || 0}
        </SectionHeader>
      </StatContainer>
      <StatContainer>
      <Description>
          Total Users
        </Description>
        <SectionHeader>
          {feesAndUsers.data?.users.uniqueCountCumulative || 0}
        </SectionHeader>
      </StatContainer>
      </StatsContainer>
    </MainContainer>
  );
};

export default Stats;
