import {
  CarousellWrapper,
  CarousellTextItem,
  CarousellContainer,
  StarIcon,
  CarousellTextWrapper,
} from "./styled";

import Marquee from "react-fast-marquee";

import starIcon from "assets/carousselStar.svg";

const CarousellText = [
  "Traders Wanted",
  "Get Funded",
  "Start Your Challenge",
  "Traders Wanted",
  "Get Funded",
  "Start Your Challenge",
  "Traders Wanted",
  "Get Funded",
  "Start Your Challenge",
  "Traders Wanted",
  "Get Funded",
  "Start Your Challenge",
  "Traders Wanted",
  "Get Funded",
  "Start Your Challenge",
  "Traders Wanted",
  "Get Funded",
  "Start Your Challenge",
];

const Carousell = () => {
  return (
    <CarousellWrapper>
      <Marquee speed={50}>
        <CarousellContainer>
          {CarousellText.map((word, index) => (
            <CarousellTextWrapper>
              <CarousellTextItem key={index}>{word}</CarousellTextItem>
              <StarIcon src={starIcon} />
            </CarousellTextWrapper>
          ))}
        </CarousellContainer>
      </Marquee>
    </CarousellWrapper>
  );
};

export default Carousell;
